<template>
  <div>
    <p class="mb-2">
      Willkommen <b>{{ currentUser().firstName }}!</b> Hier findest du deine
      wichtigsten Informationen im Überbick!
    </p>
    <DashboardSalesHints />
    <p class="mb-2" />

    <BasicDashboardRevenue />
    <BasicDashboardOrders />
    <BasicDashboardInquires />
    <BasicDashboardOffers />
    <BasicDashboardStorages />
    <!-- <b-card no-body>
      <b-card-header class="align-items-baseline">
        <div>
          <b-card-title class="mb-25">
            Verkäufe 2021
          </b-card-title>
          <b-card-text class="mb-0">
            -
          </b-card-text>
        </div>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="text-muted cursor-pointer"
        />
      </b-card-header>

      <b-card-body class="pb-0">
       
        <vue-apex-charts
          type="line"
          height="240"
          :options="salesLine.chartOptions"
          :series="salesLine.series"
        />
      </b-card-body>
    </b-card> -->
  </div>
</template>

<script>
import { BCard, BRow, BCol, BCardText, BCardTitle } from 'bootstrap-vue'
import axios from 'axios'
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line import/no-unresolved
import API from '@/service/api'
import BasicDashboardOffers from './BasicDashboardOffers.vue'
import BasicDashboardInquires from './BasicDashboardInquires.vue'
import BasicDashboardStorages from './BasicDashboardStorages.vue'
import BasicDashboardOrders from './BasicDashboardOrders.vue'
import BasicDashboardRevenue from './BasicDashboardRevenue.vue'
import DashboardSalesHints from './DashboardSalesHints.vue'
import DashboardTiles from './DashboardTiles'

export default {
  setup() {
    const { currentUser } = DashboardTiles()
    return {
      currentUser,
    }
  },

  components: {
    BCard,
    VueApexCharts,
    BRow,
    BCol,
    BCardText,
    BCardTitle,
    DashboardTiles,
    BasicDashboardStorages,
    BasicDashboardInquires,
    BasicDashboardOffers,
    BasicDashboardOrders,
    BasicDashboardRevenue,
    DashboardSalesHints,
    API,
  },
  data() {
    return {
      baseURL: API().defaults.baseURL,
      posts: [],
      errors: [],
      salesLine: {
        series: [
          {
            name: 'Sales',
            data: [140, 180, 150, 205, 160, 295, 125, 255, 205, 305, 240, 295],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ['#df87f2'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'July',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
    }
  },

  // Fetches posts when the component is created.
  created() {
    axios
      .get(`${this.baseURL}/storages`)
      .then(response => {
        // JSON responses are automatically parsed.
        this.posts = response.data
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
}
</script>

<style></style>
