<template lang="">
  <div>
    <b-card class="mb-4">
      <b-card-title>Sales Tipps</b-card-title>
      <b-card-text>
        <div v-html="showHints()" />
      </b-card-text>
    </b-card>
  </div>
</template>
<script>
import { BCard, BCardText, BLink, BCardTitle, BCardBody } from 'bootstrap-vue'
import DashboardTiles from './DashboardTiles'

export default {
  setup() {
    const {
      getCountOfAllInquiries,
      getAcceptedInquiries,
      getOpenInquiries,
      getActiveOffers,
    } = DashboardTiles()

    return {
      getCountOfAllInquiries,
      getAcceptedInquiries,
      getOpenInquiries,
      getActiveOffers,
    }
  },

  components: {
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BCardBody,
    DashboardTiles,
  },

  methods: {
    showHints() {
      let hints = ''

      if (this.getAcceptedInquiries() !== 0) {
        hints += `<p><a href="/inquiries-list">Es gibt ${this.getAcceptedInquiries()} unqualifizierte Anfrage(n).</a></p>`
      }

      if (this.getActiveOffers() !== 0) {
        hints += `<p><a href="/offers-list">Es gibt ${this.getActiveOffers()} aktive(s) Angebote.</a></p>`
      }

      return hints === '' ? 'Super, alles bestens :)' : hints
    },
  },
}
</script>
<style lang=""></style>
