<template lang="">
  <div>
    <b-card no-body>
      <b-card-header>
        <h4 class="mb-0">
          Lager
        </h4>
        <b-card-text class="font-medium-5 mb-0">
          <feather-icon
            icon="HelpCircleIcon"
            size="21"
            class="text-muted cursor-pointer"
          />
        </b-card-text>
      </b-card-header>

      <!-- apex chart -->

      <b-row class="text-center mx-0">
        <b-col
          xl="4"
          md="4"
          sm="6"
          class="border-top border-right d-flex align-items-between flex-column py-1"
        >
          <statistic-card-vertical
            color="primary"
            icon="BoxIcon"
            :statistic="getCountOfAllStorages()"
            statistic-title="Anzahl"
          />
        </b-col>

        <b-col
          xl="4"
          md="4"
          sm="6"
          class="border-top border-right d-flex align-items-between flex-column py-1"
        >
          <statistic-card-vertical
            color="success"
            icon="ShoppingCartIcon"
            :statistic="getRentedStorages()"
            statistic-title="Vermietet"
          />
        </b-col>

        <b-col
          xl="4"
          md="4"
          sm="6"
          class="border-top d-flex align-items-between flex-column py-1"
        >
          <statistic-card-vertical
            color="danger"
            icon="SlashIcon"
            :statistic="getFreeStorages()"
            statistic-title="Nicht vermietet"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BCardTitle,
  BCardHeader,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import DashboardTiles from './DashboardTiles'

export default {
  setup() {
    const {
      getCountOfAllStorages,
      getFreeStorages,
      getRentedStorages,
    } = DashboardTiles()

    return {
      getCountOfAllStorages,
      getFreeStorages,
      getRentedStorages,
    }
  },
  components: {
    DashboardTiles,
    StatisticCardVertical,
    BCard,
    BRow,
    BCol,
    BCardText,
    BCardTitle,
    BCardHeader,
  },
}
</script>
<style lang=""></style>
