<template>
  <div>
    <apexchart
      type="line"
      :options="chartOptions"
      :series="series"
      height="400"
    ></apexchart>
  </div>
</template>

<script>
import ApexCharts from 'vue-apexcharts'
import DashboardTiles from './DashboardTiles'

export default {
  name: 'BarChartRevenueLastTwelveMonths',
  components: {
    apexchart: ApexCharts,
  },
  setup() {
    const { getOrderItemsMonthlyRevenue } = DashboardTiles()
    return {
      getOrderItemsMonthlyRevenue,
    }
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: '100%',
          type: 'line',
        },
        stroke: {
          width: [0, 4],
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0, 1],
          formatter(val, opts) {
            if (opts.seriesIndex === 0) {
              return `${val}€`
            }
            return `${val}`
          },
          //   offsetY: -20,
          style: {
            fontSize: '13px',
            colors: ['#304758'],
          },
        },
        xaxis: {
          categories: Object.keys(this.getOrderItemsMonthlyRevenue()),
        },
        yaxis: [
          {
            title: {
              text: 'Umsatz (€)',
            },
          },
          {
            opposite: true,
            title: {
              text: 'Lager vermietet (Anz.)',
            },
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
        },
      },
    }
  },
  mounted() {
    const monthlyRevenue = this.getOrderItemsMonthlyRevenue()

    this.series = [
      {
        name: 'Umsatz (€)',
        type: 'column',
        data: Object.keys(monthlyRevenue).map(
          month => monthlyRevenue[month].sum
        ),
      },
      {
        name: 'Lager vermietet (Anz.)',
        type: 'line',
        data: Object.keys(monthlyRevenue).map(
          month => monthlyRevenue[month].count
        ),
      },
    ]

    this.chartOptions.xaxis.categories = Object.keys(monthlyRevenue)
  },
}
</script>

<style scoped></style>
