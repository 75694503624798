<template lang="">
  <div>
    <b-card no-body>
      <b-card-header>
        <h4 class="mb-0">
          Buchungen
        </h4>
        <b-card-text class="font-medium-5 mb-0">
          <feather-icon
            icon="HelpCircleIcon"
            size="21"
            class="text-muted cursor-pointer"
          />
        </b-card-text>
      </b-card-header>

      <!-- apex chart -->

      <b-row class="text-center mx-0">
        <b-col
          xl="4"
          md="4"
          sm="6"
          class="border-top border-right d-flex align-items-between flex-column py-1"
        >
          <statistic-card-vertical
            color="primary"
            icon="FileIcon"
            :statistic="getCountOfAllOrders()"
            statistic-title="Anzahl"
          />
        </b-col>

        <b-col
          xl="4"
          md="4"
          sm="6"
          class="border-top border-right d-flex align-items-between flex-column py-1"
        >
          <statistic-card-vertical
            color="primary"
            icon="MoreHorizontalIcon"
            :statistic="getCountOfAllOrderItems()"
            statistic-title="Positionen"
          />
        </b-col>

        <b-col
          xl="4"
          md="4"
          sm="6"
          class="border-top d-flex align-items-between flex-column py-1"
        >
          <statistic-card-vertical
            color="primary"
            icon="DollarSignIcon"
            :statistic="getSumOfAllActiveOrderItems()"
            statistic-title="Einnahmen mntl."
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BCardTitle,
  BCardHeader,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import DashboardTiles from './DashboardTiles'

export default {
  setup() {
    const {
      getCountOfAllOrderItems,
      getSumOfAllActiveOrderItems,
      getCountOfAllOrders,
    } = DashboardTiles()

    return {
      getCountOfAllOrderItems,
      getSumOfAllActiveOrderItems,
      getCountOfAllOrders,
    }
  },

  components: {
    DashboardTiles,
    StatisticCardVertical,
    BCard,
    BRow,
    BCol,
    BCardText,
    BCardTitle,
    BCardHeader,
  },
}
</script>
<style lang=""></style>
